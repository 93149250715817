.footer_copyright {
    display: block;
    margin: 2.5rem 0 2rem;
    text-align: center;
    font-size: var(--smaller-font-size);
    color: var(--text-color-light);
}
.footer_copyright-link {
    color: var(--first-color);
    font-weight: var(--font-medium);
}