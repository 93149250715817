.header {
  width: 100%;
  background-color: var(--background-color);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  transition: 0.4s; /*For animation dark mode*/
}

.nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav_logo-icon,
.nav_logo,
.nav_link {
  color: var(--nav-list-color);
}

.nav_logo {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: -1px;
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
  transition: 0.3s;
}

.nav_logo-icon {
  font-size: 1.15rem;
}

.nav_logo:hover {
  color: var(--nav-list-hover-color);
}

.nav_toggle,
.nav_theme {
  display: inline-flex;
  font-size: 1.25rem;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .nav_menu {
    position: fixed;
    background-color: var(--background-color);
    width: 100%;
    height: 100vh;
    top: 56px;
    right: -100%;
    padding: 2rem 0 0 3rem;
    transition: 0.3s;
    z-index: var(--z-fixed);
    display: flex;
    justify-content: center;

  }
}

.nav_close {
  font-size: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1.25rem;
  cursor: pointer;
}

.nav_list {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.nav_link {
  color: var(--nav-list-color);
  font-weight: var(--font-medium);
  transition: 0.3s;
}

.nav_link:hover {
  color: var(--nav-list-hover-color);
}

.nav_buttons {
  display: inline-flex;
  align-items: center;
  column-gap: 1rem;
}

.change-theme {
  color: var(--nav-list-color);
  font-size: 1.15rem;
  cursor: pointer;
  transition: .3s;
}

.change-theme:hover {
  color: var(--nav-list-hover-color);
}

.mobile-icon {
  color: var(--nav-list-color);
}

.mobile-icon:hover {
  color: var(--nav-list-hover-color);
}

/* Show menu */
.show-menu {
  right: 0;
}
.scroll-shadow {
  box-shadow: 0 1px 4px var(--nav-bottom-line);
}


.active-link {
  position: relative;
  color: var(--nav-list-hover-color);
  transition: .3s;
}

.active-link::after {
  content: "";
  position: absolute;
  bottom: 0.1rem;
  left: 12px;
  width: 80%;
  height: 1px;
  background-color: var(--first-color);
}

@media screen and (max-width: 320px) {
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}

@media screen and (min-width: 767px) {
  body {
    margin: 0;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
    column-gap: 3rem;
  }
  .nav_toggle {
    display: none;
  }

  .nav_list {
    flex-direction: row;
    column-gap: 3rem;
  }
  .nav_menu {
    margin-left: auto;
  }
}

@media screen and (min-width: 992px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
}
