.profile {
    margin-top: 3.5rem;
}

.profile_container {
    row-gap: 2rem;
    padding-top: 3.5rem;
}

.profile_data {
    display: grid;
    text-align: center;
}


.profile_figure-center{
    justify-self: center;
    display: grid;
    place-items: center;
    margin-bottom: 0.75rem;
}

.profile_figure img {
    width: 200px;
}

.profile_name {
    font-size: var(--h1-font-size);
    color: var(--nav-list-color);
}

.profile_profession {
    font-size: var(--small-font-size);
    font-weight: var(--font-normal);
    color: var(--first-color);
    margin-bottom: var(--mb-1);
}

.profile_social {
    display: flex;
    justify-content: center;
    column-gap: var(--mb-0-75);
}
.profile_social-icon {
    font-size: 1.8rem;
}

.profile_social-link {
    font-size: 1.25rem;
    color: var(--nav-list-color);
    transition: 0.3s;
}

.profile_social-link:hover {
    color: var(--first-color);
    transform: translateY(-0.3rem);
}




