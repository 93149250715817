.scrollup {
    position: fixed;
    background-color: var(--first-color);
    right: 1rem;
    bottom: -30%;
    display: inline-flex;
    padding: .5rem;
    border-radius: .25rem;
    z-index: 10;
    opacity: .8;
    transition: .4s;
}

.scrollup-icon {
    font-size: 1rem;
    font-weight: 900;
    color: white;
}

.scrollup:hover {
    background-color: var(--first-color-alt);
    opacity: 1;
}

.show-button {
    bottom: 5rem;
}