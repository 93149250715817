.projects_section {
  padding: 2rem;
  border-radius: 15px;
  min-width: 345px;
  background-color: rgb(221, 219, 219);
  /* background-color: #ce99ff; */
  /* background-image: radial-gradient(at 34% 43%, hsla(167, 71%, 74%, 1) 0px, transparent 50%),
                    radial-gradient(at 81% 81%, hsla(256, 84%, 63%, 1) 0px, transparent 50%); */
}

.projects_content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 767px) {
  .projects_content {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 576px) {
  .projects_content {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
  }
}
