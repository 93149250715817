
.profession_button-group {
    padding: 1rem 0.375rem 0;
    display: flex;
    justify-content: space-between;
    column-gap: 1.5rem;
    margin: 0 auto;
  }
  
  .profession_button {
    padding: 0.5rem;
    color: var(--title-color);
    font-size: var(--normal-font-size);
    font-family: var(--body-font);
    font-weight: var(--font-medium);
    cursor: pointer;
    transition: 0.3s;
  }
  
  .profession_button:hover {
    color: var(--first-color);
  }


  
@media screen and (max-width: 450px) {
    .profession_button-group {
      display: grid;
      row-gap: 1rem;
      place-items: center;
      grid-template-columns: repeat(1, max-content);
    }
  
    .profession_button {
      font-size: 1.2rem;
    }
    
  }
  