@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600&display=swap");

:root {
  --header-height: 3.5rem;

  --hue: 152;
  --first-color: #F22D44;
  --first-color-alt: #F38894;
  --first-color-light: #F3BFC3;
  --first-color-lighten: hsl(var(--hue), 24%, 92%);
  --text-color: hsl(var(--hue), 4%, 35%);
  --text-color-light: hsl(var(--hue), 4%, 55%);
  --body-color: hsl(var(--hue), 0%, 100%);
  --container-color: #fff;

  --body-font: "Jost", sans-serif;
  --big-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;

  --z-tooltip: 10;
  --z-fixed: 100;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* Color Theme */

.dark {
  --background-color: #324152;
  /* --background-color: #00214A; */
  --background-color-reverse: #F6F4F5;
  --heading-color: #fff;
  --title-color: rgb(238, 238, 238);
  --title-color-reverse: hsl(var(--hue), 4%, 15%);
  --nav-bar-color-bg: rgba(68, 68, 68, 0.548);
  --nav-list-color: rgb(232, 232, 232);
  --nav-list-hover-color: rgb(126, 126, 126);
  --nav-items-bg: #444;
  --text-color: #ebebeb;
  --nav-bottom-line: #962443;
  --dark-mode-bg: rgb(182, 182, 182);
  --neutro-hover-color: black;
  --dark-mode-btn-color: #ffffff;
  
}

.light {
  --background-color: #F6F4F5;
  --background-color-reverse: #324152;
  /* --background-color-reverse: #00214A; */
  --heading-color: rgb(41, 41, 41);
  --title-color: hsl(var(--hue), 4%, 15%);
  --title-color-reverse: rgb(238, 238, 238);
  --nav-bar-color-bg: rgba(255, 255, 255, 0.548);
  --nav-list-color: rgb(37, 37, 37);
  --nav-items-bg: #fff;
  --nav-bottom-line: #b4b4b4;
  --nav-list-hover-color: rgb(150, 150, 150);
  --neutro-hover-color: white;
  --text-color: #282828;
  --dark-mode-bg: rgb(201, 201, 201);
  --dark-mode-btn-color: #DD2255;;
  ;
}


body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--background-color);
  color: var(--text-color);
  transition: .3s;
}

html {
  scroll-behavior: smooth;
}
h1,h2,h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}



.container {
  max-width: 968px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.section {
  padding: 5.5rem 0 1rem;
}

.section-title::before {
  content: attr(data-top-title);
  display: block;
  color: var(--first-color);
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: .5rem;

}

.title_center {
  text-align: center;
  margin-bottom: 3.5rem;
}



::-webkit-scrollbar {
  width: 0.6rem;
  border-radius: .5rem;
  background-color: var(--first-color-light);
}

::-webkit-scrollbar-thumb {
  border-radius: .5rem;
  background-color: var(--first-color-alt);
}

::-webkit-scrollbar-thumb:hover {
  border-radius: .5rem;
  background-color: var(--first-color);
}


/* ************************************* */

.main {
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
/* 
.bento-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 20px;
  background-color: rgb(227, 227, 227);
  padding: 40px;
  border-radius: 12px;
}

.card {
  padding: 30px;
  background-color: rgb(246, 246, 246);
  border-radius: 12px;
  
  
}

.card:nth-child(1){
  grid-column: span 2 / span 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  background-color: rgb(146, 244, 48);
color: rgb(59, 59, 59);
}

.card:nth-child(2){
  grid-column: span 1 / span 1;
  grid-row: span 2 / span 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: cornflowerblue;
  color: rgb(80, 80, 80);
}

.card:nth-child(3){
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: cadetblue;
}
.card:nth-child(4){
  grid-column: span 1 / span 1;
  grid-row: span 1 / span 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: coral;
}


.title {
  font-size: 16px;
}

.price {
  font-weight: bold;
}

.card:nth-child(2) .picture img {
  width: 100%;
}

.card:nth-child(1) .picture img {
  width: 100%;
}


.icons-tech i {
  font-size: 25px;
  font-weight: 500;
}
.card-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card-flex {
  display: flex;
  flex-direction: column;
  gap: 10px;
} */



/* ***************************************** */


@media screen and (min-width: 968px) {
  :root {
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }

  .container{
    margin-left: auto;
    margin-right: auto;
  }

}

