.profession_section {
  margin-left: auto;
  margin-right: auto;
}

.profession_info {
  grid-template-columns: repeat(3, max-content);
  justify-content: center;
  column-gap: 2.5rem;
  margin-bottom: 2rem;
  display: none;
  transition: 0.3s;
}

.profession_info-group {
  text-align: center;
}

.profession_info-number {
  background-color: var(--first-color-light);
  color: #fff;
  padding: 0.8rem;
  border-radius: 0.2rem;
  margin-bottom: var(--mb-0-5);
}

.profession_info-group .profession_info-description {
  font-size: var(--smaller-font-size);
  font-weight: var(--font-medium);
  color: var(--title-color);
}

.skills_content {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 6.5rem;
  margin-bottom: 2rem;
  display: none;
  transition: .3s;
}

.skills_title {
  font-size: var(--h3-font-size);
  text-align: center;
  margin-bottom: 1.5rem;
  color: var(--first-color);
}

.skills_box {
  display: flex;
  justify-content: center;
  column-gap: 2rem;
}

.skills_group {
  display: grid;
  align-content: flex-start;
  row-gap: 1rem;
}

.skills_data {
  display: flex;
  column-gap: 0.5rem;
}

.skills_data i {
  font-size: 1rem;
  color: var(--first-color);
}

.skills_name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 1.2rem;
}

.skills_level {
  font-size: var(--smaller-font-size);
}

.show_data {
  display: grid;
}

.profession_buttons-cv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profession_button-cv {
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
  background-color: var(--first-color);
  color: #fff;
  padding: 1rem 1.2rem;
  border-radius: 0.2rem;
  transition: 0.3s;
}

.profession_button-cv i {
  font-size: 1.25rem;
}

.profession_button-cv:hover {
  background-color: var(--first-color-alt);
}

@media screen and (max-width: 450px) {
  .profession_section {
    position: relative;
  }

  .profession_info {
    grid-template-columns: repeat(1, max-content);
  }

  .profession_info-number {
    padding: 0.8rem 1.8rem;
    border-radius: 0.5rem;
    font-size: var(--h1-font-size);
  }
  .profession_info-description {
    font-size: var(--normal-font-size);
  }
  .skills_content {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 3.5rem;
  }
  

  .skills_box {
    column-gap: 2.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
