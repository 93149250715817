.card {
  transition: 0.3s;
  animation: ease-in;
  background-color: var(--background-color-reverse);
  color: var(--text-color);
  border-radius: 0.8rem;
  padding: 1.2rem 1.8rem;
}

.card:hover {
  transform: scale(1.01);
  box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.28);
}

.card-links {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  justify-content: end;
}

.card-links a {
  font-size: 22px;
  color: var(--title-color-reverse);
}

.card-body {
  display: flex;
  flex-direction: column;
}

.card-title, 
.card-description {
  margin-bottom: 0.5rem;
  color: var(--title-color-reverse);
}

.card-description {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 40px;
}

.card-stack {
  font-size: small;
  color: rgb(222, 44, 79);
  /* font-weight: bold; */
}

.card-body .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}



.card-body .card-footer .tech {
  color: rgb(222, 44, 79);
  font-size: 1.3rem;
}
.card-body .card-footer .tech a box-icon{
  color: var(--background-color);
  transition: 0.3s;
}

.card_body .card-footer .tech .icon {
  color: rgb(222, 44, 79);
}

