/* .tag-list {
  list-style: none;
}

.tag-list li {
  padding: 1rem;
  background-color: aliceblue;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 0.8rem -0.25rem lightgray;
}

.scroll-tech {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scroller {
  max-width: 600px;
  color: black;
}

.scroller__inner {
  padding-block: 1rem;
  flex-wrap: wrap;
  display: flex;
  gap: 1rem;
}

.scroller[data-animated="true"] {
  overflow: hidden;
  
    90deg,
    transparent,
    black 20%,
    black,
    80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, black 20%, black, 80%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
  flex-wrap: nowrap;
  animation: scroll 20s linear infinite;
  width: max-content;
}

@keyframes scroll {
  to {
    transform: translate(100%);
  }
} */

*::before,
*::after {
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  height: 100px;
  max-width: 1536px;
  margin-inline: auto;
  position: relative;
  overflow: hidden;
  margin-top: 3rem;
  margin-bottom: 3rem;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
  -webkit-mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0)
  );
}

@keyframes scrollleft {
  to {
    left: -200px;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 100px;
  border-radius: 6px;
  position: absolute;
  left: max(calc(110px*8), 100%);
  /* left: 100%; */
  animation-name: scrollleft;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: -10s;
}

.item1 {
  animation-delay: calc(30s / 8 * (8 - 8) );
}
.item2 {
  animation-delay: calc(30s / 8 * (8 - 7) );
}
.item3 {
  animation-delay: calc(30s / 8 * (8 - 6) );
}
.item4 {
  animation-delay: calc(30s / 8 * (8 - 5) );
}
.item5 {
  animation-delay: calc(30s / 8 * (8 - 4) );
}
.item6 {
  animation-delay: calc(30s / 8 * (8 - 3) );
}
.item7 {
  animation-delay: calc(30s / 8 * (8 - 2) );
}
.item8 {
  animation-delay: calc(30s / 8 * (8 - 1) );
} /**/


.item img {
  width: 35%;
}
